'use client';

import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { CookieData, useCookieStore } from '@/layout/Layout/components/CookiePopup/store/cookieStore';

import { NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID } from '@/config/env';
import { resetTrackScrollDepth, sendGtmEvent, trackScrollDepth } from './gtagHelpers';

export const GoogleTagManagerInitializer = () => {
    const [isHydrated, setIsHydrated] = useState(false);
    const { cookiePopupShown, cookieData } = useCookieStore();
    const pathname = usePathname();

    function tagManagerConsentUpdate(data: CookieData) {
        if (window.gtag === undefined) return;

        window.gtag('consent', 'update', {
            ad_storage: data.advertising ? 'granted' : 'denied',
            analytics_storage: data.analytics ? 'granted' : 'denied',
            personalization_storage: data.preferences ? 'granted' : 'denied',
        });
    }

    useEffect(() => {
        useCookieStore.persist.rehydrate();
        setIsHydrated(true);
    }, []);

    useEffect(() => {
        if (!isHydrated || !cookiePopupShown) return;
        tagManagerConsentUpdate(cookieData);
    }, [isHydrated, cookiePopupShown, cookieData]);

    useEffect(() => {
        if (!isHydrated) return;

        trackScrollDepth();

        return () => {
            resetTrackScrollDepth();
        };
    }, [isHydrated, pathname]);

    // Track page views
    useEffect(() => {
        if (!isHydrated) return;

        setTimeout(() => {
            sendGtmEvent({
                action: 'page_view',
                category: 'Engagement',
            });
        }, 250);
    }, [isHydrated, pathname]);

    if (!isHydrated) {
        return null;
    }

    return (
        <>
            <Script id="gtag" strategy="afterInteractive">
                {`
         // Define dataLayer and the gtag function.
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         
         // Set default consent parameters
         if(${cookiePopupShown}){
            gtag('consent', 'default', {
                'ad_storage': ${cookieData.advertising} ? 'granted' : 'denied',
                'analytics_storage': ${cookieData.analytics} ? 'granted' : 'denied',
                'functionality_storage': 'granted',
                'personalization_storage': ${cookieData.preferences} ? 'granted' : 'denied',
                'security_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied'
            });
        } else {
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'functionality_storage': 'granted',
                'personalization_storage': 'denied',
                'security_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied'
            });
        }
      `}
            </Script>
        </>
    );
};
